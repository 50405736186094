<template>
  <div>
    <v-card
      flat
      min-height="100vh"
      class="d-flex align-center"
      style="z-index: 1;">
      <v-card-text>
        <div
          class="text-h4 text-center font-weight-bold mb-2"
          :style="`color: ${set_color};`">
          YIA
        </div>

        <div
          class="body-1 text-center">
          Yayasan Islam Abdurrahman bin Auf
        </div>

        <div
          class="px-13 ma-4 mx-13">
          <v-progress-linear
            indeterminate
            height="8"
            rounded
            :color="set_color">
          </v-progress-linear>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      set_color () {
        // return this.$store.state.header.config.color
        return '#9E9E9E'
      }
    }
  }
</script>